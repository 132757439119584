<template>
  <div class="faq">
    <div class="content">
      <div class="title">Frequently Asked Questions</div>
      <div class="list">
        <div v-for="(item, index) in value" :key="index" @click="click(index)">
          <div class="title">
            <div>{{ item.title }}</div>
            <i v-if="list.indexOf(index) > -1" class="el-icon-arrow-up"></i>
            <i v-else class="el-icon-arrow-down"></i>
          </div>
          <div :class="['val', { 'expanded': list.indexOf(index) > -1 }]">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
    <div class="block bar">
      <div class="block_box">
        <div class="title">Still Need Help?</div>
        <div class="hint">Our customer support is here to help.</div>
        <el-button type="primary" @click="$router.push('/ContactUs')">Contact us</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Faq',
  data() {
    return {
      list: [],
      value: [
        {
          title: 'What is AjeLoan?',
          content: 'Ajeloan is a financial loan service provided by Rubystar GIobal Limited. We make it easy for you to apply for a loan and receive your loan into your bank account in minutes 24/7.',
        },
        {
          title: 'I have a complaint or feedback; how do I contact you?',
          content: 'Please send an email to ajeloanservice@gmail.com or reach out through our social media platforms.',
        },
        {
          title: 'How can I get an instant loan?',
          content: 'You need an apple phone and a BVN. That\'s all! Download the Ajeloan app from the Apple Store. Sign up with the phone number linked to your BVN, answer a few questions and confirm your identity to receive a loan offer. If you accept it, you will receive your loan instantly in your preferred bank account!',
        },
        {
          title: 'What makes Ajeloan different from other lenders?',
          content: 'Speed of delivery – You can get a loan instantly in 5 minutes, from anywhere in Nigeria \n'
            + 'Flexibility – You can repay on or before your due date \n'
            + 'No hidden charges – Our interest and fees charges are all-inclusive, ensuring transparency \n'
            + 'The customer is King – Our team is trained to treat you with the utmost respect at all times',
        },
        {
          title: 'Why was my loan application rejected?',
          content: 'Apologies on that, you can apply again in a few days! It might be for different reasons:\n'
            + '- Not enough information found on the phone: You should increase your phone usage (Calls/SMS) to be eligible.\n'
            + '- We decided not to give you a credit at this time after analyzing your profile. Keep using your bank account with healthy revenues to get a loan soon.',
        },
        {
          title: 'How can I repay?',
          content: 'Click on the "Repay" button in the app to pay via card or bank transfer.',
        },
        {
          title: 'How do I check my account balance and payment date?',
          content: 'Please log in in the app, you will find your customized dashboard that contains your credit status and/or all the details concerning your current balance.',
        },
        {
          title: 'What happens if I don\'t pay back?',
          content: 'We are humans and understand that unexpected and unforeseen situations may occur.\n'
            + '\n'
            + 'However, we do not reschedule payment dates, you should take a loan only if you have the capacity to repay it to avoid:\n'
            + '\n'
            + '- Late fees \n'
            + '- Suspension of your Ajeloan account\n'
            + '- Report to national credit bureaus as required by law (CRC, First Central)',
        },
        {
          title: 'Why do you request permissions on my phone when installing the Ajeloan app?',
          content: 'Ajeloan collect and processes securely data from your phone (SMS, device details, location, phonebook) to make a lending decision. This data is necessary for us to approve your loan in real-time without asking you for cumbersome paperwork.',
        },
      ],
    };
  },
  methods: {
    click(val) {
      const index = this.list.indexOf(val);
      if (index === -1) {
        this.list.push(val);
      } else {
        this.list.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
  @mixin pixel-ratio() {}
.faq {
  @include pixel-ratio();
  .bar {
    .block_box {
      background-image: url('~@/assets/img/faq_banner.png');
    }
  }
  .ratio {
    .bar {
      .block_box {
        background-image: url('~@/assets/img/faq_banner@2x.png');
      }
    }
  }
  .top {
    width: 100%;
    display: block;
  }
  .content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    text-align: left;
    >.title {
      padding-top: 15px;
      padding-bottom: 15px;
      color: #333333;
      font-size: 24px;
      font-weight: bold;
      border-bottom: 0.5px solid #CCCCCC;
    }
    .list {
      font-size: 18px;
      >div {
        border-bottom: 0.5px solid #CCCCCC;
      }
      .title {
        padding: 25px 0px;
        cursor: pointer;
        font-size: 15px;
        color: #333333;
        display: flex;
        div {
          flex: 1;
        }
        i {
          color: #999999;
        }
      }
      .val {
        overflow: hidden;
        max-height: 0px;
        transition: padding .8s ease,max-height .4s ease;
        padding-top: 0px;
        font-size: 12px;
        line-height: 30px;
        font-weight: initial;
        color: #666666;
        margin-top: 0px;
        &.expanded {
          max-height: 530px;
          padding-top: 10px;
          padding-bottom: 20px;
        }
      }
    }
  }
  .block {
    .title {
      font-size: 23px;
      color: #333333;
    }
    .text {
      font-size: 14px;
      color: #333333;
      padding: 10px 0px;
    }
    .hint {
     font-size: 13px;
     color: #999999;
     padding: 10px 0px;
    }
    .el-button {
      margin-top: 15px;
    }
    &_box {
      max-width: 1200px;
      margin: 0 auto;
    }
    &.bar {
      text-align: center;
      .block_box {
        padding: 50px 10px;
        background-position: center;
        background-size: cover;
        margin-top: 80px;
        margin-bottom: 50px;
      }

    }
  }
}
</style>
